import { initializeApp } from 'firebase/app'
import { getFirestore, collection, where, query, getDocs } from 'firebase/firestore'
import {useCollection} from "vuefire";
// ... other firebase imports

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyCmEFclJsTMRRAsOk3sFzzKn4zH4CRkzFw",
    authDomain: "my-profile-b5d3d.firebaseapp.com",
    projectId: "my-profile-b5d3d",
    storageBucket: "my-profile-b5d3d.appspot.com",
    messagingSenderId: "947193897660",
    appId: "1:947193897660:web:abc17b2ca15c60f050e6b9",
    measurementId: "G-M1CPLGWJDB"
})

// used for the firestore refs
export const db = getFirestore(firebaseApp)

// here we can export reusable database references
export const usersRef = collection(db, 'users')
// export const q = query(usersRef);
export const q = query(usersRef);


