<template>
  <div>
    <Vue3Lottie
        animationLink="https://assets6.lottiefiles.com/packages/lf20_ofa3xwo7.json"
        :height="300"
        :width="300"
    />
    <div class="text-center">
      <h3 class="font-semibold text-blue-600">Đang tìm kiếm thông tin...</h3>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue3Lottie } from 'vue3-lottie';
import {defineComponent} from "vue"
export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "error",
  components: {
    Vue3Lottie
  }
})

</script>

<style scoped lang="scss"></style>
